import React from "react"
import { graphql } from "gatsby"
import GlobalComponents from "../../components/globalcomponents"
import SocialMeta from "../../components/socialMeta"
import styles from "../../templates/work.module.scss"
import AvenueImg from "../../components/AvenueImg"
import RelatedCaseStudy from "../../components/caseStudies/relatedCaseStudyV2"
import GetInTouch from "../../components/getInTouch"
import { parseBlockContent } from "../../components/parseBlockContent"
import HtmlTextNew from "../../components/htmlTextNew"
import CSBodyCopyCMS from "../../components/CSBodyCopyCMS/CSBodyCopyCMS"

const Who = ({ data }) => {
  const { uri, seo, content, avWho } = data.post || {}

  const ctaStrip = avWho?.ctaStrip
  const relatedContent = avWho?.relatedContent
  return (
    <GlobalComponents>
      <SocialMeta
        twitterImage={seo.opengraphImage?.sourceUrl}
        ogImage={seo.opengraphImage?.sourceUrl}
        ogTitle={seo.title}
        ogURL={uri}
      />

      <CaseStudyHeader caseStudy={data.post} />
      <CSBodyCopyCMS html={avWho?.intro?.content} image={avWho?.intro?.image} />
      {content && (
        <div style={{ overflow: "hidden" }}>
          <HtmlTextNew htmlBlock={parseBlockContent(content)} />
        </div>
      )}
      <GetInTouch
        left={
          ctaStrip?.heading || "Think we might be a good fit for your project?"
        }
        right={ctaStrip?.buttonText || "Let's have a chat"}
        cols={[9, 1, 2]}
      />
      {relatedContent?.heading && (
        <RelatedCaseStudy
          title={relatedContent?.heading}
          image={relatedContent?.image}
          link={relatedContent?.link}
        />
      )}
    </GlobalComponents>
  )
}

export default Who

const CaseStudyHeader = ({ caseStudy }) => {
  return (
    <header className={styles.header}>
      <div className="row">
        <AvenueImg
          loading="eager"
          className={styles.headerImage}
          fluid={caseStudy.headerSettings.headerImage}
        />

        <div className={styles.gradient} />
        <div className="columns large-12">
          <span className={styles.flag}>CLIENT TYPES</span>
          <h1 className={styles.title}>{caseStudy.title}</h1>
        </div>
      </div>
    </header>
  )
}

export const pageQuery = graphql`
  query ($id: String!) {
    post: wpWho(id: { eq: $id }) {
      title
      uri
      content
      date(formatString: "DD MMM YY")
      headerSettings {
        headerImage {
          sourceUrl
          srcSet
          mediaDetails {
            height
            width
          }
        }
      }
      avWho {
        intro {
          content
          image {
            sourceUrl
            srcSet
          }
          imageVariation
        }
        ctaStrip {
          heading
          buttonText
        }
        relatedContent {
          heading
          link
          image {
            sourceUrl
            srcSet
            mediaDetails {
              height
              width
            }
          }
        }
      }

      seo {
        metaDesc
        title
        opengraphImage {
          sourceUrl
        }
      }
    }
  }
`
